<template>
<div>
  <onboarding-header>
    <template #right-header-component>
      <div class="tw-flex tw-items-center">
        <span class="text">Already have an account?   <span class="action" @click="$router.push({name:'LoginView'})">Log In</span></span>

      </div>
    </template>

  </onboarding-header>
  <div class="lg:tw-mt-16">
    <ChangePassword/>
  </div>
</div>
</template>

<script>
import ChangePassword from "@/components/onboarding/ChangePassword";
import OnboardingHeader from "@/components/reusables/OnboardingHeader";
export default {
name: "LoginView",
  components: {OnboardingHeader, ChangePassword}
}
</script>

<style scoped>
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #8692A6;
}
.action{
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #008EEF;
  cursor: pointer;
  /*margin-left: 13px;*/
}

</style>
