<template>
  <div class="tw-p-5">
    <div class="lg:tw-w-3/12 md:tw-w-5/12 tw-pt-20" style="margin: 0 auto">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(changePassword)">
        <div class="tw-my-16">
          <h4 class="find">Create New Password</h4>
        </div>
        <div class="tw-mt-4">
          <small>New Password</small>
          <validation-provider
              v-slot="{ errors }"
              name="New password"
              rules="required|min:6">
          <v-text-field
              id="newPassword"
              hide-details
              label="Enter new password"
              v-model="password"
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'text' : 'password'"
              solo/>
            <span class="err">{{ errors[0] }}</span>
          </validation-provider>
        </div>
          <br>
          <div>
            <small>Confirm Password</small>
            <validation-provider
                name="Confirm password"
                rules="required|min:6">
              <v-text-field
                  id="confirmPassword"
                  hide-details
                  label="Confirm password"
                  v-model="confirmPassword"
                  :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value1 = !value1)"
                  :type="value1 ? 'text' : 'password'"
                  solo/>
              <span class="err">{{ passwordError }}</span>
            </validation-provider>
          </div>

          <br>
          <br>
        <div class="tw-mt-2">
          <BaseButton :loading="loading" :block="true" button-text="Create password"/>
        </div>
        <div>

        </div>
      </form>
      </validation-observer>
    </div>

  </div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
import {changePassword} from "@/services/api/AuthApiService";

export default {
  name: "ChangePassword",
  components: {BaseButton},
  data(){
    return{
      value: false,
      value1:false,
      password: '',
      confirmPassword:'',
      loading:false,
      passwordError:''
    }
  },

  methods:{
    validatePassword() {
      if (this.password === this.confirmPassword) {
        return true;
      } else {
        this.passwordError = "Password mismatch";
        return false;
      }
    },
    changePassword(){
      const { user_id, timestamp, signature } = this.$route.query;
      if (!this.validatePassword()) {
        setTimeout(() => {
          this.passwordError = "";
        }, 3000);
        return;
      }else this.loading = true
      let data = {
        user_id,
        timestamp,
        signature,
        password:this.password
      };
      changePassword(data).then(
          response => {
            this.$displaySnackbar({
              message: response.data.detail,
              success: true
            })
            this.$router.push('/login')
          })
      .catch(error=>{
            this.$displaySnackbar({
              message: error.response.data.detail,
              success: false
            })

          })
      .finally(()=> this.loading = false
      )
    }

  }
}
</script>

<style scoped>
.find {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #001343;
}


.or{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #475661;
}
.forget{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  color: #008EEF;
}
.err{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: red;

}
</style>
